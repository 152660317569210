import React, { useState, useContext, useEffect } from 'react'
import { Container, Tooltip, makeStyles, Button, Typography } from '@material-ui/core'
import Page from 'src/components/Page'
import ReactHighmaps from 'react-highcharts/ReactHighmaps'
import spain from '../../../js/spain'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { saveSpainVote } from '../../../Services/VoteService'
import UserProvider from '../../../context/UserProvider'
import Spinner from '@material-ui/core/CircularProgress'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#303030',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: 80,
    '@media screen and (min-width: 800px)': {
      paddingTop: theme.spacing(3)
    }
  },
  map: {
    height: 300,
    '@media screen and (min-width: 800px)': {
      height: 700
    }
  },
  selectedText: {
    fontSize: 22,
    marginRight: 20,
    '@media screen and (min-width: 800px)': {
      fontSize: 28,
      marginRight: 0
    }
  },
  icon: {
    fontSize: '20px',
    color: '#fff',
    '@media screen and (min-width: 800px)': {
      fontSize: '25px',
      color: '#fff'
    }
  },
  selectedContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 20px',
    marginBottom: 0,
    '@media screen and (min-width: 800px)': {
      padding: '20px 200px',
      marginBottom: 20
    }
  },
  country: {
    color: '#fa5190',
    marginLeft: 5
  },
  selectContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  votedContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#B2FF59',
    '@media screen and (min-width: 800px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    }
  },
  alreadyVoted: {
    color: '#FFE0B2',
    fontSize: 18,
    '@media screen and (min-width: 800px)': {
      fontSize: 26
    }
  },
  button: {
    maxHeight: 35,
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      color: 'rgba(255, 255, 255, 0.6)'
    },
    marginLeft: 0,
    marginTop: 5,
    '@media screen and (min-width: 800px)': {
      marginLeft: 25,
      marginTop: 0
    }
  },
  cerveza: {
    width: 60,
    marginLeft: 0,
    marginTop: 8,
    '@media screen and (min-width: 800px)': {
      marginLeft: 20,
      marginTop: 0,
      width: 80
    }
  },
  spinner: {
    maxWidth: 20,
    maxHeight: 20,
    marginLeft: 10,
    color: 'white'
  },
  select: {
    color: '#fff'
  },
  footer: {
    color: '#616161',
    textAlign: 'center',
    marginTop: 70
  },
  loveIcon: {
    verticalAlign: 'top',
    color: 'rgba(244, 67, 54, 0.15)'
  }
}))

const MapView = () => {
  const classes = useStyles()
  const user = useContext(UserProvider.context)
  const [country, setCountry] = useState(null)
  const [voting, setVoting] = useState(false)
  const [voted, setVoted] = useState(false)
  const [alreadyVoted, setAlreadyVoted] = useState(false)

  var data = [
    ['es-pm', 0],
    ['es-va', 1],
    ['es-le', 2],
    ['es-me', 3],
    ['es-p', 4],
    ['es-s', 5],
    ['es-na', 6],
    ['es-ce', 7],
    ['es-cu', 8],
    ['es-vi', 9],
    ['es-ss', 10],
    ['es-gr', 11],
    ['es-mu', 12],
    ['es-bu', 13],
    ['es-sa', 14],
    ['es-za', 15],
    ['es-hu', 16],
    ['es-m', 17],
    ['es-gu', 18],
    ['es-sg', 19],
    ['es-se', 20],
    ['es-t', 21],
    ['es-te', 22],
    ['es-v', 23],
    ['es-bi', 24],
    ['es-or', 25],
    ['es-l', 26],
    ['es-z', 27],
    ['es-gi', 28],
    ['es-ab', 29],
    ['es-a', 30],
    ['es-av', 31],
    ['es-cc', 32],
    ['es-to', 33],
    ['es-ba', 34],
    ['es-co', 35],
    ['es-h', 36],
    ['es-c', 37],
    ['es-ma', 38],
    ['es-po', 39],
    ['es-lo', 40],
    ['es-so', 41],
    ['es-al', 42],
    ['es-b', 43],
    ['es-ca', 44],
    ['es-o', 45],
    ['es-cs', 46],
    ['es-cr', 47],
    ['es-j', 48],
    ['es-lu', 49],
    ['es-tf', 50],
    ['es-gc', 51]
  ]

  const config = {
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    chart: {
      spacingBottom: 20,
      height: window.innerWidth > 800 ? 700 : 300,
      backgroundColor: '#303030'
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    plotOptions: {
      map: {
        allAreas: true,
        joinBy: ['hc-key', 'code'],
        dataLabels: {
          enabled: true,
          color: 'black',
          style: {
            fontWeight: 'bold'
          }
        },
        mapData: spain,
        tooltip: {
          enabled: false,
          headerFormat: '',
          pointFormat: '{point.name}: <b>{series.name}</b>'
        }
      }
    },
    series: [
      {
        name: '',
        cursor: 'pointer',
        data: data.map(item => {
          return { code: item[0] }
        }),
        borderColor: '#e3e3e3',
        dataLabels: {
          enabled: true,
          format: '{point.name}',
          style: {
            fontFamily: 'Roboto'
          }
        },
        events: {
          click: e => {
            if (!alreadyVoted && !voted) {
              if (country == null || e.point.name !== country) {
                setCountry(e.point.name)
              }
            }
          }
        }
      }
    ]
  }

  const onVote = () => {
    setVoting(true)
    saveSpainVote(country).then(res => {
      if (res.status === 201) {
        setVoted(true)
      } else if (res.status === 204) {
        setAlreadyVoted(true)
      } else {
        //error
      }
      setCountry(null)
      setVoting(false)
    })
  }

  const onVoteAgain = () => {
    setVoted(false)
    setAlreadyVoted(false)
  }

  useEffect(() => {}, [])

  return (
    <Page className={classes.root} title="IlloJuan - Españita">
      <Container maxWidth="xl">
        <div className={classes.selectedContainer}>
          {country && !voted && (
            <>
              <Typography variant="h2" className={classes.selectedText}>
                <LocationOnIcon className={classes.icon} /> <span className={classes.country}>{country}</span>
              </Typography>
              {user && (
                <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVote}>
                  Votar
                  {voting && <Spinner className={classes.spinner} />}
                </Button>
              )}
              {!user && (
                <Tooltip className={classes.tooltip} title="Conecta tu cuenta de Twitch para votar" arrow placement="top">
                  <div>
                    <Button className={classes.button} color="primary" variant="contained" disabled>
                      Votar
                    </Button>
                  </div>
                </Tooltip>
              )}
            </>
          )}
          {!country && !voted && !alreadyVoted && (
            <div className={classes.selectContainer}>
              <Typography variant="h2" className={classes.select}>
                Illo selecciona una provincia
              </Typography>
            </div>
          )}
          {voted && (
            <div className={classes.votedContainer}>
              <Typography variant="h2" className={classes.voted}>
                Bien votado compadre
              </Typography>
              <img className={classes.cerveza} alt="cerveza" src="/static/images/cerveza.png" />
              <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVoteAgain}>
                Votar otra vez
              </Button>
            </div>
          )}
          {alreadyVoted && (
            <div className={classes.votedContainer}>
              <Typography variant="h2" className={classes.alreadyVoted}>
                No seas ansias que ya has votado en esta, espera a la siguiente
              </Typography>
              <img className={classes.cerveza} alt="cafe" src="/static/images/cafe.png" />
              <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVoteAgain}>
                Votar otra vez
              </Button>
            </div>
          )}
        </div>
        <div className={classes.map}>
          <ReactHighmaps config={config} className={classes.map} />
        </div>
        <Typography className={classes.footer}>
          Made with <FavoriteBorderIcon className={classes.loveIcon} /> for illojuaners
        </Typography>
      </Container>
    </Page>
  )
}

export default MapView
