import React, { useState, useEffect, useContext } from 'react'
import { makeStyles, Button, Dialog, Typography, Grid, Chip, FormControlLabel, Checkbox, LinearProgress } from '@material-ui/core'
import Page from 'src/components/Page'
import { getAllVotes, wipeAllVotes } from '../../../Services/VoteService'
import Spinner from '@material-ui/core/CircularProgress'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { HorizontalBar } from 'react-chartjs-2'
import UserProvider from '../../../context/UserProvider'
import 'chartjs-plugin-datalabels'

var INTERVAL = null

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#292929',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  containerRoot: {
    maxWidth: 2200
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0',
    position: 'relative'
  },
  button: {
    maxHeight: 35,
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      color: 'rgba(255, 255, 255, 0.6)'
    }
  },
  spinner: {
    maxWidth: 35,
    maxHeight: 35,
    marginLeft: 10,
    position: 'absolute',
    right: 40
  },
  waitingContainer: {
    padding: '30px 0'
  },
  waitContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  sofa: {
    marginTop: 80,
    width: 800,
    borderRadius: 15
  },
  sofaContainter: {
    display: 'flex',
    justifyContent: 'center'
  },
  waitingText: {
    color: '#ffffff',
    fontSize: 40,
    marginTop: 80
  },
  linearProgress: {
    height: 1,
    marginTop: 20
  },
  leftChart: {
    width: 1000,
    height: 700
  },
  rightChart: {
    width: 1000,
    height: 700
  },
  chartsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 140
  },
  chartTitle: {
    color: '#fff',
    fontSize: 42,
    textAlign: 'center',
    marginTop: 80,
    marginBottom: 50
  },
  emote: {
    width: 55,
    margin: '0 30px',
    verticalAlign: 'sub'
  },
  privateContainer: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 150
  },
  privateEmote: {
    width: 120
  },
  privateTitle: {
    fontSize: 32,
    marginTop: 20
  },
  privateSubtitle: {
    fontSize: 26,
    marginTop: 10
  },
  checkboxLabel: {
    marginLeft: 50,
    color: '#fff',
    '& .MuiTypography-root.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.4)'
    }
  },
  allCheckbox: {
    color: 'rgba(51, 255, 75, 0.8) !important'
  },
  subsCheckbox: {
    color: 'rgba(192, 66, 255, 0.8) !important',
    '&.Mui-disabled': {
      color: 'rgba(192, 66, 255, 0.3) !important'
    }
  },
  viewersCheckbox: {
    color: 'rgba(54, 162, 235, 0.9) !important',
    '&.Mui-disabled': {
      color: 'rgba(54, 162, 235, 0.3) !important'
    }
  },
  checkboxRow: {
    display: 'flex',
    flexDirection: 'column'
  },
  footer: {
    color: '#616161',
    textAlign: 'center',
    marginTop: 270
  },
  loveIcon: {
    verticalAlign: 'top',
    color: 'rgba(244, 67, 54, 0.15)'
  },
  totalVotesBadge: {
    backgroundColor: '#616161',
    color: '#fff',
    padding: '0px 10px',
    textAlign: 'center',
    display: 'inline-block',
    borderRadius: 10,
    fontSize: 32,
    marginLeft: 20,
    marginRight: 10
  },
  dialogRoot: {
    '& .MuiPaper-root': {
      backgroundColor: '#292929',
      padding: 20,
      maxHeight: '80%'
    },
    color: '#fff'
  },
  suscriptor: {
    verticalAlign: 'initial',
    marginLeft: 10,
    backgroundColor: '#6200EA',
    color: '#ffffff',
    marginTop: 2
  },
  viewer: {
    verticalAlign: 'initial',
    marginLeft: 10,
    backgroundColor: '#2962FF',
    color: '#ffffff',
    marginTop: 2
  },
  number: {
    marginRight: 8
  }
}))

const MapView = () => {
  const classes = useStyles()
  const user = useContext(UserProvider.context)
  const [resetting, setResetting] = useState(false)
  const [subsValues, setSubsValues] = useState([])
  const [viewersValues, setViewersValues] = useState([])
  const [subsVotes, setSubsVotes] = useState([])
  const [subsTotalVotes, setSubsTotalVotes] = useState(0)
  const [viewersVotes, setViewersVotes] = useState([])
  const [viewersTotalVotes, setViewersTotalVotes] = useState(0)
  const [allVotes, setAllVotes] = useState([])
  const [allValues, setAllValues] = useState([])
  const [allTotalVotes, setAllTotalVotes] = useState(0)
  const [subsLog, setSubsLog] = useState([])
  const [viewersLog, setViewersLog] = useState([])
  const [allLog, setAllLog] = useState([])
  const [dialogLog, setDialogLog] = useState([])
  const [viewSubs, setViewSubs] = useState(true)
  const [viewViewers, setViewViewers] = useState(true)
  const [viewAll, setViewAll] = useState(false)
  const [open, setOpen] = useState(false)

  const viewersData = {
    labels: viewersVotes,
    datasets: [
      {
        label: 'Votos',
        data: viewersValues,
        backgroundColor: [
          'rgba(54, 162, 235, 0.5)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(54, 162, 235, 0.2)'
        ],
        borderColor: [
          'rgba(84, 201, 255)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  const subsData = {
    labels: subsVotes,
    datasets: [
      {
        label: 'Votos',
        data: subsValues,
        backgroundColor: [
          'rgba(192, 66, 255, 0.45)',
          'rgba(192, 66, 255, 0.2)',
          'rgba(192, 66, 255, 0.2)',
          'rgba(192, 66, 255, 0.2)',
          'rgba(192, 66, 255, 0.2)',
          'rgba(192, 66, 255, 0.2)',
          'rgba(192, 66, 255, 0.2)',
          'rgba(192, 66, 255, 0.2)',
          'rgba(192, 66, 255, 0.2)',
          'rgba(192, 66, 255, 0.2)'
        ],
        borderColor: [
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)',
          'rgba(192, 66, 255, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  const allData = {
    labels: allVotes,
    datasets: [
      {
        label: 'Votos',
        data: allValues,
        backgroundColor: [
          'rgba(51, 255, 75, 0.45)',
          'rgba(51, 255, 75, 0.2)',
          'rgba(51, 255, 75, 0.2)',
          'rgba(51, 255, 75, 0.2)',
          'rgba(51, 255, 75, 0.2)',
          'rgba(51, 255, 75, 0.2)',
          'rgba(51, 255, 75, 0.2)',
          'rgba(51, 255, 75, 0.2)',
          'rgba(51, 255, 75, 0.2)',
          'rgba(51, 255, 75, 0.2)'
        ],
        borderColor: [
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)',
          'rgba(51, 255, 75, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 24,
            fontColor: 'white'
          },
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 12,
            fontColor: 'white'
          },
          gridLines: {
            display: false
          }
        }
      ]
    },
    legend: {
      display: false
    },
    layout: {
      padding: {
        right: 80
      }
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: val => {
          //return val + ' kg'
          return val
        },
        color: '#ffffff',
        font: {
          weight: 'bold',
          size: 20
        },
        anchor: 'end',
        align: 'right',
        offset: 20
      }
    },
    tooltips: {
      enabled: false
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    }
  }

  const handleClose = () => {
    setOpen(false)
    setDialogLog([])
  }

  const onReset = () => {
    setResetting(true)

    wipeAllVotes().then(res => {
      if (res.status === 200) {
        setSubsValues([])
        setSubsVotes([])
        setViewersVotes([])
        setViewersValues([])
        setAllVotes([])
        setAllValues([])
        setViewersTotalVotes(0)
        setAllTotalVotes(0)
        setSubsTotalVotes(0)
        setResetting(false)
      } else {
        //error
      }
    })
  }

  const getData = () => {
    getAllVotes(true).then(res => {
      if (res.status === 200 && !resetting) {
        setSubsVotes(res.data.votes)
        setSubsValues(res.data.values)
        setSubsTotalVotes(res.data.totalVotes)
        setSubsLog(res.data.log)
      } else {
        //error
      }
    })
    getAllVotes(false).then(res => {
      if (res.status === 200 && !resetting) {
        setViewersVotes(res.data.votes)
        setViewersValues(res.data.values)
        setViewersTotalVotes(res.data.totalVotes)
        setViewersLog(res.data.log)
      } else {
        //error
      }
    })
  }

  const getAllData = () => {
    getAllVotes('all').then(res => {
      if (res.status === 200 && !resetting) {
        setAllVotes(res.data.votes)
        setAllValues(res.data.values)
        setAllTotalVotes(res.data.totalVotes)
        setAllLog(res.data.log)
      } else {
        //error
      }
    })
  }

  const handleViewAllChange = e => {
    clearInterval(INTERVAL)
    setViewAll(e.target.checked)
    if (e.target.checked) {
      setViewSubs(true)
      setViewViewers(true)
      getAllData()
      INTERVAL = setInterval(() => {
        getAllData()
      }, 3000)
    } else {
      getData()
      INTERVAL = setInterval(() => {
        getData()
      }, 3000)
    }
  }
  const handleViewSubsChange = e => {
    setViewSubs(e.target.checked)
  }
  const handleViewViewersChange = e => {
    setViewViewers(e.target.checked)
  }

  useEffect(() => {
    if (user && (user.id === '90075649' || user.id === '171586400')) {
      getData()
      INTERVAL = setInterval(() => {
        getData()
      }, 3000)
    }
  }, [user])

  return (
    <Page className={classes.root} title="IlloJuan - Panel">
      {user && user.id !== '90075649' && user.id !== '171586400' && (
        <div className={classes.privateContainer}>
          <img className={classes.privateEmote} alt="arbol" src="/static/images/arbol.png" />
          <Typography className={classes.privateTitle}>Illo te has perdido,</Typography>
          <Typography className={classes.privateSubtitle}>
            para votar es <a href="/">aquí</a>
          </Typography>
        </div>
      )}
      {user === null && (
        <div className={classes.privateContainer}>
          <img className={classes.privateEmote} alt="arbol" src="/static/images/arbol.png" />
          <Typography className={classes.privateTitle}>Illo te has perdido,</Typography>
          <Typography className={classes.privateSubtitle}>
            para votar es <a href="/">aquí</a>
          </Typography>
        </div>
      )}
      {user && (user.id === '90075649' || user.id === '171586400') && (
        <>
          <div className={classes.row}>
            {false && <Spinner className={classes.spinner} />}
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              disabled={resetting || (subsValues.length === 0 && viewersValues.length === 0 && allValues.length === 0)}
              onClick={onReset}
            >
              Reiniciar votación
            </Button>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={<Checkbox className={classes.allCheckbox} checked={viewAll} onChange={handleViewAllChange} name="viewAll" />}
              label="Ver todos"
            />
            <div className={classes.checkboxRow}>
              <>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      disabled={viewAll}
                      className={classes.subsCheckbox}
                      checked={viewSubs}
                      onChange={handleViewSubsChange}
                      name="viewSubs"
                    />
                  }
                  label="Ver illojuaners que pagan"
                />
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      className={classes.viewersCheckbox}
                      disabled={viewAll}
                      checked={viewViewers}
                      onChange={handleViewViewersChange}
                      name="viewViewers"
                    />
                  }
                  label="Ver illojuaners que no pagan"
                />
              </>
            </div>
          </div>
          <LinearProgress className={classes.linearProgress} />
          {(subsValues.length > 0 || viewersValues.length > 0 || allValues.length > 0) && (
            <div className={classes.chartsContainer}>
              {viewSubs && !viewAll && (
                <div className={classes.leftChart}>
                  <Typography className={classes.chartTitle}>
                    <img className={classes.emote} alt="ole" src="/static/images/ole.png" />
                    Illojuaners que pagan {subsTotalVotes > 0 && <div className={classes.totalVotesBadge}>{subsTotalVotes}</div>}
                    <img className={classes.emote} alt="top" src="/static/images/top.png" />
                  </Typography>
                  <HorizontalBar
                    data={subsData}
                    options={options}
                    onElementsClick={elems => {
                      if (elems[0]) {
                        let province = subsVotes[elems[0]._index]
                        setDialogLog(subsLog.filter(obj => obj.vote === province))
                        setOpen(true)
                      }
                    }}
                  />
                </div>
              )}
              {viewViewers && !viewAll && (
                <div className={classes.rightChart}>
                  <Typography className={classes.chartTitle}>
                    <img className={classes.emote} alt="facepalm" src="/static/images/facepalm.png" />
                    Illojuaners que no pagan {viewersTotalVotes > 0 && <div className={classes.totalVotesBadge}>{viewersTotalVotes}</div>}
                    <img className={classes.emote} alt="calvo" src="/static/images/calvo.png" />
                  </Typography>
                  <HorizontalBar
                    data={viewersData}
                    options={options}
                    onElementsClick={elem => {
                      console.log(elem)
                    }}
                    onElementsClick={elems => {
                      if (elems[0]) {
                        let province = viewersVotes[elems[0]._index]
                        setDialogLog(viewersLog.filter(obj => obj.vote === province))
                        setOpen(true)
                      }
                    }}
                  />
                </div>
              )}
              {viewAll && (
                <div className={classes.rightChart}>
                  <Typography className={classes.chartTitle}>
                    <img className={classes.emote} alt="love" src="/static/images/love.png" />
                    Illojuaners, reuníos {allTotalVotes > 0 && <div className={classes.totalVotesBadge}>{allTotalVotes}</div>}
                    <img className={classes.emote} alt="soldado" src="/static/images/soldado.png" />
                  </Typography>
                  <HorizontalBar
                    data={allData}
                    options={options}
                    onElementsClick={elems => {
                      if (elems[0]) {
                        let province = allVotes[elems[0]._index]
                        setDialogLog(allLog.filter(obj => obj.vote === province))
                        setOpen(true)
                      }
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {viewersValues.length === 0 && subsValues.length === 0 && allValues.length === 0 && (
            <div className={classes.waitingContainer}>
              <Grid container>
                <Grid item xs={12} className={classes.waitContainer}>
                  <Typography variant="h2" className={classes.waitingText}>
                    Esperando a que los chavale voten...
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.sofaContainter}>
                  <img className={classes.sofa} alt="sofa" src="/static/images/juansofa.jpg" />
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
      <Typography className={classes.footer}>
        Made with <FavoriteBorderIcon className={classes.loveIcon} /> for illojuaners
      </Typography>
      <Dialog className={classes.dialogRoot} onClose={handleClose} open={open}>
        {dialogLog.map((obj, i) => (
          <Typography key={i}>
            <span className={classes.number}>{i + 1}.</span> <b>{obj.displayName}</b>
            {obj.sub && <Chip className={classes.suscriptor} label="Illojuaner que paga" size="small" />}
            {!obj.sub && <Chip className={classes.viewer} label="Illojuaner que no paga" size="small" />}
          </Typography>
        ))}
      </Dialog>
    </Page>
  )
}

export default MapView
