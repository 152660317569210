import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'
import Page from 'src/components/Page'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#303030',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: 80,
    '@media screen and (min-width: 800px)': {
      paddingTop: theme.spacing(3)
    }
  },
  containerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontSize: 26,
    color: '#fff',
    marginTop: 10,
    //marginBottom: 30,
    marginBottom: 10,
    textAlign: 'center',
    '@media screen and (min-width: 800px)': {
      fontSize: 36,
      marginTop: 70,
      //marginBottom: 50
      marginBottom: 20
    }
  },
  subtext: {
    fontSize: 20,
    color: '#fff',
    marginBottom: 20,
    textAlign: 'center',
    '@media screen and (min-width: 800px)': {
      fontSize: 24,
      marginBottom: 60
    }
  },
  gif: {
    borderRadius: 50,
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    maxWidth: '100%',
    '@media screen and (min-width: 800px)': {
      borderRadius: 80
    }
  },
  footer: {
    color: '#616161',
    textAlign: 'center',
    marginTop: 70
  },
  loveIcon: {
    verticalAlign: 'top',
    color: 'rgba(244, 67, 54, 0.15)'
  }
}))

const MapView = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="IlloJuan">
      <Container className={classes.containerRoot} maxWidth="lg">
        <Typography className={classes.text}>🔥 Se vienen cositas 🤙</Typography>
        <Typography className={classes.subtext}>
          Espera patrocinada por <a href="https://www.ogsbrand.com/">OG's</a> 😎
        </Typography>
        <img className={classes.gif} alt="gif" src="/static/images/illojuan.gif" />
        <Typography className={classes.footer}>
          Made with <FavoriteBorderIcon className={classes.loveIcon} /> for illojuaners
        </Typography>
      </Container>
    </Page>
  )
}

export default MapView
