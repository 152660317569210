import axios from 'axios'
const API_URL = 'https://api.illojuan.tv'

export const saveVote = vote => {
  return axios.post(API_URL + '/vote/saveVote', { vote: vote }, { withCredentials: true }).then(res => res)
}

export const saveSpainVote = vote => {
  return axios.post(API_URL + '/vote/saveSpainVote', { vote: vote }, { withCredentials: true }).then(res => res)
}

export const saveTekkenVote = fighter => {
  return axios.post(API_URL + '/vote/saveTekkenVote', { vote: fighter }, { withCredentials: true }).then(res => res)
}

export const saveCommand = command => {
  return axios.post(API_URL + '/vote/saveCommand', { command: command }, { withCredentials: true }).then(res => res)
}

export const getAllVotes = sub => {
  return axios.post(API_URL + '/vote/giveMeAllVotes', { sub: sub }, { withCredentials: true }).then(res => res)
}

export const wipeAllVotes = () => {
  return axios.post(API_URL + '/vote/wipeAllTheVotes', {}, { withCredentials: true }).then(res => res)
}
