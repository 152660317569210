import React, { createContext, useState, useEffect } from 'react'
import { getUser } from '../Services/TwitchService'
const context = createContext(null)

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(undefined)

  /*useEffect(() => {
    getUser()
      .then(res => {
        if (res.status === 200) {
          setUser(res.data)
        } else {
          setUser(null)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [])*/

  return <context.Provider value={user}>{children}</context.Provider>
}

UserProvider.context = context

export default UserProvider
