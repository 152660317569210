import React, { useState, useContext, useEffect } from 'react'
import { Container, makeStyles, Typography, Grid, Button } from '@material-ui/core'
import Page from 'src/components/Page'
import { saveCommand } from '../../../Services/VoteService'
import UserProvider from '../../../context/UserProvider'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { TwitchEmbed, TwitchChat } from 'react-twitch-embed'

const COMMANDS = []

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingTop: 60,
    '@media screen and (min-width: 1000px)': {
      paddingTop: theme.spacing(3)
    }
  },
  footer: {
    color: '#616161',
    textAlign: 'center',
    marginTop: 50
  },
  loveIcon: {
    verticalAlign: 'top',
    color: 'rgba(244, 67, 54, 0.15)'
  },
  upperContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  gamepad: {
    width: 400,
    height: 'fit-content'
  },
  lowerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginLeft: 80
  }
}))

const MapView = () => {
  const classes = useStyles()
  const user = useContext(UserProvider.context)
  const [fighter, setFighter] = useState(null)
  const [voting, setVoting] = useState(false)
  const [voted, setVoted] = useState(false)
  const [alreadyVoted, setAlreadyVoted] = useState(false)
  const [open, setOpen] = useState(false)

  const onVote = command => {
    setVoting(true)
    saveCommand(command).then(res => {
      if (res.status === 201) {
        setVoted(true)
      } else if (res.status === 204) {
        setAlreadyVoted(true)
      } else {
        //error
      }
      setFighter(null)
      setVoting(false)
    })
  }

  const onVoteAgain = () => {
    setFighter(null)
    setVoted(false)
    setAlreadyVoted(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {}, [])

  return (
    <Page className={classes.root} title="IlloJuan - Mando">
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} className={classes.upperContainer}>
            <img className={classes.gamepad} alt="gamepad" src="/static/images/gamepad/gamepad.png" />
            <div className={classes.buttonsContainer}>
              <Button onClick={() => onVote('circulo')} variant="contained" color="primary">
                Círculo
              </Button>
              <Button onClick={() => onVote('cuadrado')} variant="contained" color="primary">
                Cuadrado
              </Button>
              <Button onClick={() => onVote('triangulo')} variant="contained" color="primary">
                Triángulo
              </Button>
              <Button onClick={() => onVote('equis')} variant="contained" color="primary">
                Equis
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.lowerContainer}>
            <TwitchEmbed channel="illojuan" id="illojuan" theme="dark" muted onVideoPause={() => console.log(':(')} />
          </Grid>
        </Grid>
      </Container>
      <Typography className={classes.footer}>
        Made with <FavoriteBorderIcon className={classes.loveIcon} /> for illojuaners
      </Typography>
    </Page>
  )
}

export default MapView
