import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { AppBar, Toolbar, makeStyles, Typography, Container, Button, Chip } from '@material-ui/core'
import Logo from 'src/components/Logo'
import UserProvider from '../../context/UserProvider'
import { logout } from '../../Services/TwitchService'

//const CALLBACK_URL = 'http://localhost:5000/twitch/callback/'
//const CALLBACK_URL = 'https://illojuan.herokuapp.com/twitch/callback/'
const CALLBACK_URL = 'https://api.illojuan.tv/twitch/callback/'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#1b191c'
  },
  toolbar: {
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '@media screen and (min-width: 800px)': {
      height: 64,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  noUserToolbar: {
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 0,
    paddingBottom: 10,
    '@media screen and (min-width: 800px)': {
      height: 64,
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  logo: {
    width: 0,
    '@media screen and (min-width: 800px)': {
      width: 45
    }
  },
  title: {
    color: '#ffffff',
    marginLeft: 20,
    display: 'inline-block',
    fontSize: 18,
    marginTop: 8,
    '@media screen and (min-width: 800px)': {
      fontSize: 24,
      marginTop: 0
    }
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    '@media screen and (min-width: 800px)': {
      alignItems: 'center',
      flexDirection: 'row'
    }
  },
  twitchConnect: {
    backgroundColor: '#9146ff',
    '&:hover': {
      backgroundColor: '#6441a5'
    }
  },
  twitch: {
    width: 15,
    marginRight: 10
  },
  avatar: {
    borderRadius: '100%',
    width: 25,
    marginLeft: 10,
    marginRight: 5,
    verticalAlign: 'top'
  },
  suscriptor: {
    verticalAlign: 'top',
    marginLeft: 5,
    marginTop: 10,
    backgroundColor: '#6200EA',
    color: '#ffffff',
    '@media screen and (min-width: 800px)': {
      marginTop: 0
    }
  },
  viewer: {
    verticalAlign: 'top',
    marginLeft: 5,
    backgroundColor: '#2962FF',
    color: '#ffffff'
  },
  twitchDisconnect: {
    color: '#EEEEEE',
    borderColor: '#757575',
    '&:hover': {
      borderColor: '#BDBDBD'
    },
    marginTop: 10,
    '@media screen and (min-width: 800px)': {
      marginTop: 0
    }
  }
})

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles()
  const user = useContext(UserProvider.context)

  const disconnect = () => {
    logout()
      .then(res => {
        if (res.status === 200) {
          window.location.href = '/'
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Container maxWidth="lg">
        <Toolbar className={user ? classes.toolbar : classes.noUserToolbar}>
          <RouterLink to="/" className={classes.titleContainer}>
            <Logo className={classes.logo} />
            {user && (
              <>
                <Typography variant="h3" className={classes.title}>
                  Aló, <img className={classes.avatar} alt="avatar" src={user.img} /> {user.displayName}{' '}
                </Typography>
                {user.sub && <Chip className={classes.suscriptor} label="Illojuaner que paga" size="small" />}
                {!user.sub && <Chip className={classes.viewer} label="Illojuaner que no paga" size="small" />}
              </>
            )}
            {!user && (
              <Typography variant="h3" className={classes.title}>
                Aló, presidentes
              </Typography>
            )}
          </RouterLink>
          {user === null && (
            <a
              href={
                'https://id.twitch.tv/oauth2/authorize?client_id=w4iz8spy9p2qm7fgw9m7a6fk8b1dp9&redirect_uri=' +
                CALLBACK_URL +
                '&response_type=token&scope=user_read user_subscriptions'
              }
            >
              <Button className={classes.twitchConnect} size="small" color="primary" variant="contained">
                <img className={classes.twitch} alt="twitch" src="/static/images/twitch.png" /> Conectar
              </Button>
            </a>
          )}
          {user && (
            <Button className={classes.twitchDisconnect} size="small" color="primary" variant="outlined" onClick={disconnect}>
              <img className={classes.twitch} alt="twitch" src="/static/images/twitch.png" /> Desconectar
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string
}

export default TopBar
