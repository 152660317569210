import axios from 'axios'
const API_URL = 'https://api.illojuan.tv'

/*export const auth = () => {
  return axios.get('/twitch/auth').then(res => res)
}*/

export const getUser = () => {
  return axios.get(API_URL + '/twitch/user', { withCredentials: true }).then(res => res)
}

export const logout = () => {
  return axios.get(API_URL + '/twitch/logout', { withCredentials: true }).then(res => res)
}
