import React from 'react'
import MainLayout from 'src/layouts/MainLayout'
import MapView from 'src/views/map/MapView'
import SpanishMapView from 'src/views/map/SpanishMapView'
import ResultsView from 'src/views/results/ResultsView'
import ComingSoon from 'src/views/comingsoon'
import TekkenView from 'src/views/tekken/TekkenView'
import GamepadView from 'src/views/gamepad/GamepadView'

const routes = [
  /*{
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },*/
  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { path: 'login', element: <LoginView /> },
      // { path: 'register', element: <RegisterView /> },
      // { path: '404', element: <NotFoundView /> },
      //{ path: '/', element: <MapView /> },
      { path: '/', element: <ComingSoon /> },
      //{ path: '/', element: <TekkenView /> },
      { path: 'juan', element: <ResultsView /> },
      { path: 'spain', element: <SpanishMapView /> }
      //{ path: 'pruebasgamepad', element: <GamepadView /> }
      //{ path: 'pruebastekken', element: <TekkenView /> }
      // { path: '/', element: <MapView to="/app/dashboard" /> },
      // { path: '*', element: <Navigate to="/404" /> }
    ]
  }
]

export default routes
