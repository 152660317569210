import React, { useState, useContext, useEffect } from 'react'
import {
  Container,
  Tooltip,
  makeStyles,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Card,
  CardActionArea,
  IconButton
} from '@material-ui/core'
import Page from 'src/components/Page'
import { saveTekkenVote } from '../../../Services/VoteService'
import UserProvider from '../../../context/UserProvider'
import Spinner from '@material-ui/core/CircularProgress'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import CloseIcon from '@material-ui/icons/Close'

const FIGHTERS = [
  'Asuka',
  'Baek',
  'Bruce',
  'Bryan',
  'Christie',
  'Devil Jin',
  'Feng',
  'Ganryu',
  'Heihachi',
  'Hwoarang',
  'Jack-5',
  'Jin',
  'Julia',
  'Kazuya',
  'King',
  'Law',
  'Lee',
  'Lei',
  'Marduk',
  'Mokujin',
  'Nina',
  'Panda',
  'Paul',
  'Raven',
  'Roger Jr.',
  'Steve',
  'Wang',
  'Xiaoyu',
  'Yoshimitsu'
]

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: 60,
    '@media screen and (min-width: 1000px)': {
      paddingTop: theme.spacing(3)
    }
  },
  selectedText: {
    fontSize: 22,
    marginRight: 20,
    '@media screen and (min-width: 1000px)': {
      fontSize: 28,
      marginRight: 0
    }
  },
  juanFighter: {
    width: '30px',
    verticalAlign: 'bottom',
    marginRight: 10,
    '@media screen and (min-width: 1000px)': {
      width: '40px'
    }
  },
  selectedContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 20px',
    marginBottom: 0,
    '@media screen and (min-width: 1000px)': {
      padding: '20px 200px',
      marginBottom: 20
    }
  },
  fighter: {
    color: '#fa5190',
    marginLeft: 5
  },
  selectContainer: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center'
  },
  votedContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#B2FF59',
    '@media screen and (min-width: 1000px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    }
  },
  alreadyVoted: {
    color: '#FFE0B2',
    fontSize: 18,
    '@media screen and (min-width: 1000px)': {
      fontSize: 26
    }
  },
  button: {
    maxHeight: 35,
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      color: 'rgba(255, 255, 255, 0.6)'
    },
    marginLeft: 0,
    marginTop: 5,
    '@media screen and (min-width: 1000px)': {
      marginLeft: 25,
      marginTop: 0
    }
  },
  cerveza: {
    width: 60,
    marginLeft: 0,
    marginTop: 8,
    '@media screen and (min-width: 1000px)': {
      marginLeft: 20,
      marginTop: 0,
      width: 80
    }
  },
  spinner: {
    maxWidth: 20,
    maxHeight: 20,
    marginLeft: 10,
    color: 'white'
  },
  select: {
    color: '#fff',
    textAlign: 'center'
  },
  fightersContainer: {
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  fighterGrid: {
    flexGrow: 0,
    maxWidth: '25%',
    flexCasis: '25%',
    marginTop: 20,
    '@media screen and (min-width: 1000px)': {
      flexGrow: 0,
      maxWidth: '10%',
      flexCasis: '10%'
    }
  },
  fighterCard: {
    background: 'transparent',
    transition: 'transform .2s ease',
    '&:hover': {
      transform: 'scale(1.35)'
    }
  },
  zoom: {
    transform: 'scale(1.35)',
    backgroundColor: '#424242'
  },
  fighterImg: {
    width: '100%',
    marginTop: 10,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 5
  },
  fighterName: {
    fontSize: 14,
    textAlign: 'center',
    color: '#B9F6CA',
    marginTop: 10,
    '@media screen and (min-width: 900px)': {
      fontSize: 18
    },
    '@media screen and (min-width: 1600px)': {
      fontSize: 22
    }
  },
  fighterDivider: {
    backgroundColor: '#37474F',
    width: '70%',
    margin: '10px auto'
  },
  footer: {
    color: '#616161',
    textAlign: 'center',
    marginTop: 70
  },
  loveIcon: {
    verticalAlign: 'top',
    color: 'rgba(244, 67, 54, 0.15)'
  },
  mobileHowButton: {
    fontSize: 18,
    display: 'block',
    margin: '0 auto',
    marginBottom: '20px !important',
    fontWeight: 'bold',
    backgroundColor: '#00897B',
    '&:hover': {
      backgroundColor: '#00695C'
    },
    '@media screen and (min-width: 1000px)': {
      display: 'none'
    }
  },
  howButton: {
    display: 'none',
    maxWidth: 200,
    width: 200,
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 150,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#00897B',
    '&:hover': {
      backgroundColor: '#00695C'
    },
    '@media screen and (min-width: 1000px)': {
      display: 'initial'
    }
  },
  dialogRoot: {
    '& .MuiPaper-root': {
      backgroundColor: '#303030'
    }
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer'
  },
  dialogTitle: {
    textAlign: 'center'
  },
  dialogSubtitle: {
    textAlign: 'center',
    fontSize: 24,
    marginBottom: 20,
    fontWeight: 'bold'
  },
  keyboardContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 30
  },
  keyboard: {},
  key: {
    fontWeight: 'bold',
    fontSize: 22,
    color: '#00E5FF'
  },
  command: {
    fontWeight: 'bold',
    fontSize: 20
  },
  ps2button: {
    width: 35,
    verticalAlign: 'middle',
    marginLeft: 5
  },
  fasi: {
    width: 40,
    verticalAlign: 'middle',
    marginRight: 5
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: 22,
    marginTop: 10,
    marginBottom: 10
  },
  dialogDivider: {
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: '#616161'
  },
  aviso: {
    margin: '0 auto',
    display: 'block',
    marginBottom: 15
  },
  example: {
    color: '#00E5FF',
    fontWeight: 'bold'
  }
}))

const MapView = () => {
  const classes = useStyles()
  const user = useContext(UserProvider.context)
  const [fighter, setFighter] = useState(null)
  const [voting, setVoting] = useState(false)
  const [voted, setVoted] = useState(false)
  const [alreadyVoted, setAlreadyVoted] = useState(false)
  const [open, setOpen] = useState(false)

  const onVote = () => {
    setVoting(true)
    saveTekkenVote(fighter).then(res => {
      if (res.status === 201) {
        setVoted(true)
      } else if (res.status === 204) {
        setAlreadyVoted(true)
      } else {
        //error
      }
      setFighter(null)
      setVoting(false)
    })
  }

  const onVoteAgain = () => {
    setFighter(null)
    setVoted(false)
    setAlreadyVoted(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {}, [])

  return (
    <Page className={classes.root} title="IlloJuan - Votar">
      <Container maxWidth="xl">
        <Button className={classes.mobileHowButton} variant="contained" color="primary" onClick={handleClickOpen}>
          ¿Cómo se juega?
        </Button>
        <div className={classes.selectedContainer}>
          {fighter && !voted && !alreadyVoted && (
            <>
              <Typography variant="h2" className={classes.selectedText}>
                <img className={classes.juanFighter} src="/static/images/fighter.png" alt="fighter" />{' '}
                <span className={classes.fighter}>{fighter}</span>
              </Typography>
              {user && (
                <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVote}>
                  Votar
                  {voting && <Spinner className={classes.spinner} />}
                </Button>
              )}
              {!user && (
                <Tooltip className={classes.tooltip} title="Conecta tu cuenta de Twitch para votar" arrow placement="top">
                  <div>
                    <Button className={classes.button} color="primary" variant="contained" disabled>
                      Votar
                    </Button>
                  </div>
                </Tooltip>
              )}
            </>
          )}
          {!fighter && !voted && !alreadyVoted && (
            <div className={classes.selectContainer}>
              <Typography variant="h2" className={classes.select}>
                Illo selecciona un luchador
              </Typography>
            </div>
          )}
          {voted && (
            <div className={classes.votedContainer}>
              <Typography variant="h2" className={classes.voted}>
                Bien votado compadre
              </Typography>
              <img className={classes.cerveza} alt="cerveza" src="/static/images/cerveza.png" />
              <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVoteAgain}>
                Votar otra vez
              </Button>
            </div>
          )}
          {alreadyVoted && (
            <div className={classes.votedContainer}>
              <Typography variant="h2" className={classes.alreadyVoted}>
                No seas ansias que ya has votado en esta, espera a la siguiente
              </Typography>
              <img className={classes.cerveza} alt="cafe" src="/static/images/cafe.png" />
              <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVoteAgain}>
                Votar otra vez
              </Button>
            </div>
          )}
          <Button className={classes.howButton} variant="contained" color="primary" onClick={handleClickOpen}>
            ¿Cómo se juega?
          </Button>
        </div>
        <Grid container spacing={2} className={classes.fightersContainer}>
          {FIGHTERS.map((character, i) => {
            return (
              <Grid key={character} item className={classes.fighterGrid} onClick={() => setFighter(character)}>
                <Card className={character === fighter ? `${classes.fighterCard} ${classes.zoom}` : classes.fighterCard}>
                  <CardActionArea>
                    <Typography className={classes.fighterName}>{character}</Typography>
                    <Divider className={classes.fighterDivider} />
                    <img className={classes.fighterImg} src={`/static/images/tekken/${character}.png`} alt="tekken" />
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Container>
      <Typography className={classes.footer}>
        Made with <FavoriteBorderIcon className={classes.loveIcon} /> for illojuaners
      </Typography>
      <Dialog className={classes.dialogRoot} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers>
          <CloseIcon className={classes.closeButton} onClick={handleClose} />
          <div className={classes.keyboardContainer}>
            <img className={classes.keyboard} src="/static/images/Keyboard.png" alt="keyboard" />
          </div>

          <Typography gutterBottom className={classes.dialogSubtitle}>
            Si quieres que tu luchador:
          </Typography>
          <Typography gutterBottom>
            Se mueva hacia la{' '}
            <span className={classes.command}>
              <img className={classes.ps2button} src="/static/images/Izquierda.png" alt="izquierda" /> izquierda
            </span>
            , escribe <span className={classes.key}>a</span> o <span className={classes.key}>A</span> en el chat.
          </Typography>
          <Typography gutterBottom>
            Se mueva hacia la{' '}
            <span className={classes.command}>
              <img className={classes.ps2button} src="/static/images/Derecha.png" alt="derecha" /> derecha
            </span>
            , escribe <span className={classes.key}>d</span> o <span className={classes.key}>D</span> en el chat.
          </Typography>
          <Typography gutterBottom>
            Se mueva hacia{' '}
            <span className={classes.command}>
              <img className={classes.ps2button} src="/static/images/Arriba.png" alt="arriba" /> arriba
            </span>
            , escribe <span className={classes.key}>w</span> o <span className={classes.key}>W</span> en el chat.
          </Typography>
          <Typography gutterBottom>
            Se mueva hacia{' '}
            <span className={classes.command}>
              <img className={classes.ps2button} src="/static/images/Abajo.png" alt="abajo" /> abajo
            </span>
            , escribe <span className={classes.key}>s</span> o <span className={classes.key}>S</span> en el chat.
          </Typography>
          <Typography gutterBottom>
            De un{' '}
            <span className={classes.command}>
              <img className={classes.ps2button} src="/static/images/Cuadrado.png" alt="cuadrado" /> puñetazo con la derecha
            </span>
            , escribe <span className={classes.key}>4</span> en el chat.
          </Typography>
          <Typography gutterBottom>
            De un{' '}
            <span className={classes.command}>
              <img className={classes.ps2button} src="/static/images/Triangulo.png" alt="triangulo" /> puñetazo con la izquierda
            </span>
            , escribe <span className={classes.key}>8</span> en el chat.
          </Typography>
          <Typography gutterBottom>
            De una{' '}
            <span className={classes.command}>
              <img className={classes.ps2button} src="/static/images/Equis.png" alt="equis" /> patada con la izquierda
            </span>
            , escribe <span className={classes.key}>2</span> en el chat.
          </Typography>
          <Typography gutterBottom>
            De una{' '}
            <span className={classes.command}>
              <img className={classes.ps2button} src="/static/images/Circulo.png" alt="circulo" /> patada con la derecha
            </span>
            , escribe <span className={classes.key}>6</span> en el chat.
          </Typography>
          <Divider className={classes.dialogDivider} />
          <Typography gutterBottom>
            Cada intervalo de tiempo (0.5 segundos, 1 segundo... lo que elija Juan), se evalúan todos los mensajes del chat que haya habido
            para tu luchador y se pulsa la tecla que haya dicho la mayoría.
          </Typography>
          <Divider className={classes.dialogDivider} />
          <img className={classes.aviso} src="/static/images/aviso.jpg" alt="aviso" />
          <Typography gutterBottom>
            Si te salta esta limitación, lo importante es que tu mensaje <b>empiece</b> por uno de los ocho comandos. Ej: Si quieres pulsar
            el triángulo, puedes escribir <span className={classes.example}>8</span>, <span className={classes.example}>8a</span>,{' '}
            <span className={classes.example}>81</span>, <span className={classes.example}>8 g</span>,{' '}
            <span className={classes.example}>8 hola</span>, etc... todos son válidos.
          </Typography>
          <Divider className={classes.dialogDivider} />
          <Typography gutterBottom>
            A veces el chat de Twitch va de lado y carga los mensajes por bloques, por lo que cabe la posibilidad de que en un intervalo tu
            luchador no se mueva. No es porque no estés spammeando teclas a muerte, si no que los mensajes llegarán seguramente en el
            siguiente intervalo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Typography gutterBottom className={classes.dialogFooter}>
            <img className={classes.fasi} src="/static/images/fasi.png" alt="fasi" /> no? Diviértete!
          </Typography>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default MapView
