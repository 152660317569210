import React, { useState, useContext, useEffect } from 'react'
import { Container, Tooltip, makeStyles, Button, Divider, Typography } from '@material-ui/core'
import Page from 'src/components/Page'
import ReactHighmaps from 'react-highcharts/ReactHighmaps'
import maps from '../../../js/worldMedium'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { saveVote } from '../../../Services/VoteService'
import UserProvider from '../../../context/UserProvider'
import Spinner from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#303030',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: 80,
    '@media screen and (min-width: 800px)': {
      paddingTop: theme.spacing(3)
    }
  },
  map: {
    height: 300,
    '@media screen and (min-width: 800px)': {
      height: 700
    }
  },
  selectedText: {
    fontSize: 22,
    marginRight: 20,
    '@media screen and (min-width: 800px)': {
      fontSize: 28,
      marginRight: 0
    }
  },
  icon: {
    fontSize: '20px',
    color: '#fff',
    '@media screen and (min-width: 800px)': {
      fontSize: '25px',
      color: '#fff'
    }
  },
  selectedContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 20px',
    marginBottom: 0,
    '@media screen and (min-width: 800px)': {
      padding: '20px 200px',
      marginBottom: 20
    }
  },
  country: {
    color: '#fa5190',
    marginLeft: 5
  },
  selectContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  votedContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#B2FF59',
    '@media screen and (min-width: 800px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    }
  },
  alreadyVoted: {
    color: '#FFE0B2',
    fontSize: 18,
    '@media screen and (min-width: 800px)': {
      fontSize: 26
    }
  },
  button: {
    maxHeight: 35,
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      color: 'rgba(255, 255, 255, 0.6)'
    },
    marginLeft: 0,
    marginTop: 5,
    '@media screen and (min-width: 800px)': {
      marginLeft: 25,
      marginTop: 0
    }
  },
  cerveza: {
    width: 60,
    marginLeft: 0,
    marginTop: 8,
    '@media screen and (min-width: 800px)': {
      marginLeft: 20,
      marginTop: 0,
      width: 80
    }
  },
  spinner: {
    maxWidth: 20,
    maxHeight: 20,
    marginLeft: 10,
    color: 'white'
  },
  select: {
    color: '#fff'
  }
}))

const MapView = () => {
  const classes = useStyles()
  const user = useContext(UserProvider.context)
  const [country, setCountry] = useState(null)
  const [voting, setVoting] = useState(false)
  const [voted, setVoted] = useState(false)
  const [alreadyVoted, setAlreadyVoted] = useState(false)

  var data = [
    ['fo', 0],
    ['um', 1],
    ['us', 2],
    ['jp', 3],
    ['sc', 4],
    ['in', 5],
    ['fr', 6],
    ['fm', 7],
    ['cn', 8],
    ['sw', 9],
    ['sh', 10],
    ['br', 11],
    ['ec', 12],
    ['au', 13],
    ['ki', 14],
    ['ph', 15],
    ['mx', 16],
    ['es', 17],
    ['bu', 18],
    ['mv', 19],
    ['sp', 20],
    ['gb', 21],
    ['gr', 22],
    ['as', 23],
    ['dk', 24],
    ['gl', 25],
    ['gu', 26],
    ['mp', 27],
    ['pr', 28],
    ['vi', 29],
    ['ca', 30],
    ['st', 31],
    ['cv', 32],
    ['dm', 33],
    ['nl', 34],
    ['ye', 35],
    ['jm', 36],
    ['ws', 37],
    ['om', 38],
    ['vc', 39],
    ['tr', 40],
    ['bd', 41],
    ['sb', 42],
    ['lc', 43],
    ['nr', 44],
    ['no', 45],
    ['kn', 46],
    ['bh', 47],
    ['to', 48],
    ['fi', 49],
    ['id', 50],
    ['mu', 51],
    ['se', 52],
    ['tt', 53],
    ['my', 54],
    ['bs', 55],
    ['pw', 56],
    ['tv', 57],
    ['mh', 58],
    ['cl', 59],
    ['th', 60],
    ['gd', 61],
    ['ee', 62],
    ['ag', 63],
    ['tw', 64],
    ['bb', 65],
    ['it', 66],
    ['mt', 67],
    ['pg', 68],
    ['vu', 69],
    ['sg', 70],
    ['cy', 71],
    ['km', 72],
    ['fj', 73],
    ['ru', 74],
    ['va', 75],
    ['sm', 76],
    ['kz', 77],
    ['az', 78],
    ['am', 79],
    ['tj', 80],
    ['ls', 81],
    ['uz', 82],
    ['pt', 83],
    ['ma', 84],
    ['co', 85],
    ['tl', 86],
    ['tz', 87],
    ['kh', 88],
    ['ar', 89],
    ['sa', 90],
    ['pk', 91],
    ['ae', 92],
    ['ke', 93],
    ['pe', 94],
    ['do', 95],
    ['ht', 96],
    ['ao', 97],
    ['mz', 98],
    ['pa', 99],
    ['cr', 100],
    ['ir', 101],
    ['sv', 102],
    ['gw', 103],
    ['hr', 104],
    ['bz', 105],
    ['za', 106],
    ['na', 107],
    ['cf', 108],
    ['sd', 109],
    ['ly', 110],
    ['cd', 111],
    ['kw', 112],
    ['de', 113],
    ['ie', 114],
    ['kp', 115],
    ['kr', 116],
    ['gy', 117],
    ['hn', 118],
    ['mm', 119],
    ['ga', 120],
    ['gq', 121],
    ['ni', 122],
    ['ug', 123],
    ['mw', 124],
    ['tm', 125],
    ['sx', 126],
    ['zm', 127],
    ['nc', 128],
    ['mr', 129],
    ['dz', 130],
    ['lt', 131],
    ['et', 132],
    ['er', 133],
    ['gh', 134],
    ['si', 135],
    ['gt', 136],
    ['ba', 137],
    ['jo', 138],
    ['sy', 139],
    ['mc', 140],
    ['al', 141],
    ['uy', 142],
    ['cnm', 143],
    ['mn', 144],
    ['rw', 145],
    ['so', 146],
    ['bo', 147],
    ['cm', 148],
    ['cg', 149],
    ['eh', 150],
    ['rs', 151],
    ['me', 152],
    ['bj', 153],
    ['ng', 154],
    ['tg', 155],
    ['la', 156],
    ['af', 157],
    ['ua', 158],
    ['sk', 159],
    ['jk', 160],
    ['bg', 161],
    ['qa', 162],
    ['li', 163],
    ['at', 164],
    ['sz', 165],
    ['hu', 166],
    ['ro', 167],
    ['lu', 168],
    ['ad', 169],
    ['ci', 170],
    ['lr', 171],
    ['bn', 172],
    ['be', 173],
    ['iq', 174],
    ['ge', 175],
    ['gm', 176],
    ['ch', 177],
    ['td', 178],
    ['kv', 179],
    ['lb', 180],
    ['dj', 181],
    ['bi', 182],
    ['sr', 183],
    ['il', 184],
    ['ml', 185],
    ['sn', 186],
    ['gn', 187],
    ['zw', 188],
    ['pl', 189],
    ['mk', 190],
    ['py', 191],
    ['by', 192],
    ['lv', 193],
    ['bf', 194],
    ['ne', 195],
    ['tn', 196],
    ['bt', 197],
    ['md', 198],
    ['ss', 199],
    ['bw', 200],
    ['nz', 201],
    ['cu', 202],
    ['ve', 203],
    ['vn', 204],
    ['sl', 205],
    ['mg', 206],
    ['is', 207],
    ['eg', 208],
    ['lk', 209],
    ['cz', 210],
    ['kg', 211],
    ['np', 212]
  ]

  const config = {
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    chart: {
      spacingBottom: 20,
      height: window.innerWidth > 800 ? 700 : 300,
      backgroundColor: '#303030'
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    plotOptions: {
      map: {
        allAreas: true,
        joinBy: ['iso-a2', 'code'],
        dataLabels: {
          enabled: true,
          color: 'black',
          style: {
            fontWeight: 'bold'
          }
        },
        mapData: maps,
        tooltip: {
          enabled: false,
          headerFormat: '',
          pointFormat: '{point.name}: <b>{series.name}</b>'
        }
      }
    },
    series: [
      {
        name: '',
        cursor: 'pointer',
        data: data.map(item => {
          return { code: item[0].toUpperCase() }
        }),
        borderColor: '#e3e3e3',
        dataLabels: {
          enabled: true,
          format: '{point.name}',
          style: {
            fontFamily: 'Roboto'
          }
        },
        events: {
          click: e => {
            if (!alreadyVoted) {
              if (country == null || e.point.name !== country) {
                setCountry(e.point.name)
              }
            }
          }
        }
      }
    ]
  }

  const onVote = () => {
    setVoting(true)
    saveVote(country).then(res => {
      if (res.status === 201) {
        setVoted(true)
      } else if (res.status === 204) {
        setAlreadyVoted(true)
      } else {
        //error
      }
      setCountry(null)
      setVoting(false)
    })
  }

  const onVoteAgain = () => {
    setVoted(false)
    setAlreadyVoted(false)
  }

  useEffect(() => {}, [])

  return (
    <Page className={classes.root} title="IlloJuan - Votar">
      <Container maxWidth="xl">
        <div className={classes.selectedContainer}>
          {country && !voted && (
            <>
              <Typography variant="h2" className={classes.selectedText}>
                <LocationOnIcon className={classes.icon} /> <span className={classes.country}>{country}</span>
              </Typography>
              {user && (
                <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVote}>
                  Votar
                  {voting && <Spinner className={classes.spinner} />}
                </Button>
              )}
              {!user && (
                <Tooltip className={classes.tooltip} title="Conecta tu cuenta de Twitch para votar" arrow placement="top">
                  <div>
                    <Button className={classes.button} color="primary" variant="contained" disabled>
                      Votar
                    </Button>
                  </div>
                </Tooltip>
              )}
            </>
          )}
          {!country && !voted && !alreadyVoted && (
            <div className={classes.selectContainer}>
              <Typography variant="h2" className={classes.select}>
                Illo selecciona un país
              </Typography>
            </div>
          )}
          {voted && (
            <div className={classes.votedContainer}>
              <Typography variant="h2" className={classes.voted}>
                Bien votado compadre
              </Typography>
              <img className={classes.cerveza} alt="cerveza" src="/static/images/cerveza.png" />
              <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVoteAgain}>
                Votar otra vez
              </Button>
            </div>
          )}
          {alreadyVoted && (
            <div className={classes.votedContainer}>
              <Typography variant="h2" className={classes.alreadyVoted}>
                No seas ansias que ya has votado en esta, espera a la siguiente
              </Typography>
              <img className={classes.cerveza} alt="cafe" src="/static/images/cafe.png" />
              <Button className={classes.button} color="primary" variant="contained" disabled={voting} onClick={onVoteAgain}>
                Votar otra vez
              </Button>
            </div>
          )}
        </div>
        <Divider />
        <div className={classes.map}>
          <ReactHighmaps config={config} className={classes.map} />
        </div>
      </Container>
    </Page>
  )
}

export default MapView
